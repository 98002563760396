<template>
    <div>
        <el-card>
            <h1>ChmlFrp - Panel 设置</h1>
            <el-divider></el-divider>
            <el-form label-position="top" :model="background_img">
                <el-row :gutter="10">
                    <el-form-item label="自定义面板背景图片（留空为默认，请填写绝对链接）：">
                        <el-col :span="18">
                            <el-input v-model="background_img.url"></el-input>
                        </el-col>
                        <el-col :span="6">
                            <el-button style="width: 100%;" type="primary" plain @click="background_img_Data"
                                v-loading="loadingimg">确定</el-button>
                        </el-col>
                    </el-form-item>
                </el-row>
            </el-form>
            <el-form label-position="top" :model="Element_transparency">
                <el-row :gutter="10">
                    <el-form-item label="自定义面板元素透明度（0.5~1，留空为默认，有BUG推荐不设置。如果一定要设置建议0.9）：">
                        <el-col :span="18">
                            <el-input v-model="Element_transparency.opacity"></el-input>
                        </el-col>
                        <el-col :span="6">
                            <el-button style="width: 100%;" type="primary" plain @click="Element_transparency_Data"
                                v-loading="loadingopacity">确定</el-button>
                        </el-col>
                    </el-form-item>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import { getToken, removeToken } from "@/utils/setToken.js";
import axios from "axios";
export default {
    data() {
        return {
            loadingimg: false,
            loadingopacity: false,
            background_img: {
                url: '',
            },
            Element_transparency: {
                opacity: 1,
            }
        };
    },
    created() {
        const usertoken = getToken('token');

        if (usertoken) {
            this.setData();
        } else {
            removeToken('token');
            this.$router.push('/login');
            this.$message({
                message: "无效的token，请重新登录",
                type: "error",
            });
        }
    },
    methods: {
        background_img_Data(params) {

            const url = this.background_img.url;
            const usertoken = getToken('token');
            const meg = 'background_img';
            this.loadingimg = true;
            axios
                .get('https://cf-v1.uapis.cn/api/usersetup.php', {
                    params: { usertoken, url, meg, ...params }
                })
                .then(responsea => {
                    if (responsea.data.code === 200) {
                        this.$message({
                            message: "修改成功！即将跳转到登录页面以刷新网页。",
                            type: "success",
                        });
                        this.handleCommand();
                        this.$router.push('/login');
                    } else {
                        this.$message({
                            message: responsea.data.error,
                            type: "error",
                        });
                    }
                })
                .catch(responsea => {
                    console.error('请求API时发生错误:', responsea.data.error);
                })
                .finally(() => {
                    this.loadingimg = false;
                });
        },
        Element_transparency_Data(params) {
            const url = this.Element_transparency.opacity;
            const usertoken = getToken('token');
            const meg = 'Element_transparency';
            this.loadingimg = true;
            axios
                .get('https://cf-v1.uapis.cn/api/usersetup.php', {
                    params: { usertoken, url, meg, ...params }
                })
                .then(responsea => {
                    if (responsea.data.code === 200) {
                        this.$message({
                            message: "修改成功！即将跳转到登录页面以刷新网页。",
                            type: "success",
                        });
                        this.handleCommand();
                        this.$router.push('/login');
                    } else {
                        this.$message({
                            message: responsea.data.error,
                            type: "error",
                        });
                    }
                })
                .catch(responsea => {
                    console.error('请求API时发生错误:', responsea.data.error);
                })
                .finally(() => {
                    this.loadingimg = false;
                });
        },
        handleCommand() {
            // 清除token
            removeToken('token');
            removeToken('username');
            removeToken('userid');
            removeToken('userimg');
            removeToken('email');
            removeToken('tunnel');
            removeToken('usergroup');
            removeToken('qq');
            removeToken('bandwidth');
            removeToken('realname');
            removeToken('integral');
            removeToken('term');
            removeToken('background_img');

            localStorage.removeItem("back_token")
            this.$router.push('/login');
        }
    }
}
</script>